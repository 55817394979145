<template>
    <div class="NoticeList">
        <div class="main">
            <el-breadcrumb separator-class="el-icon-arrow-right" class="mb-4">
                <el-breadcrumb-item :to="{ path: '/newbidding' }">竞价大厅</el-breadcrumb-item>
                <el-breadcrumb-item>标的物</el-breadcrumb-item>
            </el-breadcrumb>
            <el-card class="box-card">
                <div class="main-title">标的物</div>
                <div class="main-body">
                    <div class="body-table">
                        <div class="table-list">
                            <div class="card-message mt-3 mb-3" v-for="(item,index) in targetListLike" :key="index">
                                <div style="float:left; border:1px solid #8c939d;height: 60px;text-align: center;margin-right: 20px;width: 100px" >
                                    <img v-if="JSON.stringify(item.thumb) != 'null'" :src="$ImgUrl + item.thumb[0]" class="card_imgsize"/>
                                    <img v-else :src="require('../assets/imgs/imgs_Notice.jpg')" class="card_imgsize"/>
                                </div>
                                <div @click="gotoDetails(item)" class="card-message_t card_size cursors">{{item.targetname}}</div>
                                <div class="card-message_b card_size" v-if="item.shw ==1">标的物所在地：{{item.areaname}} &nbsp 挂牌价格：{{item.price}} {{myUnit(item.unit)}} &nbsp 竞拍状态：{{mystate(item.outbidstate)}}</div>
                                <div class="card-message_b card_size" v-else>标的物所在地：{{item.areaname}} &nbsp 挂牌价格：{{item.shwword}}  &nbsp 竞拍状态：{{mystate(item.outbidstate)}}</div>
                                <div class="card-message_b card_size">竞价起止时间：{{item.begintime}} - {{item.endtime}}</div>
                                <div style="overflow: hidden;font-size: 14px;margin-top: 4px">
                                    <div style="float: right;color: #c0bdbd">
                                        {{item.begintime}}
                                    </div>
                                </div>
                            </div>

                        </div>
                        <!--    分页-->
                        <div class="block" align="right">
                            <el-pagination
                                    @size-change="sizeChangeHandle"
                                    @current-change="currentChangeHandle"
                                    :current-page="pageIndex"
                                    :page-sizes="[10, 20, 50, 100]"
                                    :page-size="pageSize"
                                    :total="totalPage"
                                    layout="total, sizes, prev, pager, next, jumper">
                            </el-pagination>
                        </div>
                    </div>
                </div>
            </el-card>
        </div>
    </div>
</template>

<script>
    import moment from "moment";
    import { mapGetters } from "vuex";
    export default {
        name: "HomeSearch",
        data() {
            return {
                targetListLike: [],
                pageIndex: 1,
                pageSize: 10,
                totalPage: 0,
                activeNames: ['1'],
                //分页数据
                targetForm: {
                    targetname: ''
                },
                memberInfo: {
                    id:'',
                    enterpriseid:'',
                    membername:'',
                    membercode:'',
                    tel:'',
                    sex:'',
                    birthday:''
                },
                dataListLoading: false,
                qualityTableStyle: {
                    'background': 'whitesmoke',
                    'font-weight': 'bold',
                    'font-size': ' 12px',
                    'font-family': "Microsoft YaHei",
                    'color': '#333333',
                    'text-align': 'center',
                },
            }
        },
        activated() {
            this.memberInfo = this.getUser()
            this.targetForm = this.$route.query;
            this.$nextTick(() => {
                this.getDataList()
            })

        },
        mounted() {

        },
        methods: {
            ...mapGetters(["getUser", "getNum"]),
            getDataList() {
                this.$fetch('/shoppingui/bidShopping/targetPageListLike', {
                    'page': this.pageIndex,
                    'limit': this.pageSize,
                    'targetname': this.targetForm.targetname
                }, true).then((data) => {
                    console.log(data)
                    if (data && data.code === 0) {
                        this.targetListLike = data.data.list;
                        this.totalPage = data.data.total
                    } else {
                        this.targetListLike = []
                        this.totalPage = 0
                        this.$message({
                            message: data.msg,
                            type: 'error',
                            showClose: 'true'
                        })
                    }
                })


            },
            gotoDetails(item) {
                let queryData = {};
                queryData.targetid = item.id;
                queryData.id = item.id;
                queryData.memberid = this.getUser().id;
                if (item.splitstate == 1) {
                    this.$router.push({path: "/auction_split", query: queryData});
                } else {
                    this.$router.push({path: "/auction", query: queryData});
                }

            },
            // 每页数
            sizeChangeHandle(val) {
                this.pageSize = val
                this.pageIndex = 1
                //调用查询信息接口
                this.getDataList()
            },
            // 当前页
            currentChangeHandle(val) {
                this.pageIndex = val
                //调用查询信息接口
                this.getDataList()
            },
            myUnit(e){
                return e
                // if(e =='11'){
                //     return '元/吨'
                // }
                // if(e =='12'){
                //     return '元'
                // }
                // if(e =='13'){
                //     return '元/年'
                // }
                // if(e =='14'){
                //     return '元/月'
                // }
                // if(e =='15'){
                //     return '元/日'
                // }
            },
            mystate(e){
                if(e ==0){
                    return '未开始'
                }
                if(e ==1){
                    return '竞价中'
                }
                if(e ==2){
                    return '已拍下'
                }
                if(e ==3){
                    return '已流拍'
                }
                if(e ==4){
                    return '等待结果'
                }
                if(e ==5){
                    return '公示期'
                }
            },

        }

    }
</script>

<style scoped lang="scss">
    @import "../assets/css/index.css";
    @import "../assets/css/page/zixun.scss";
    @import "../assets/css/page/NoticeList.scss";
    /* 更改element-UI按钮样式 */
    .el-button--text {
        color: #b0b0b0;
    }

    .el-button--text:hover {
        color: #b0b0b0;
        font-weight: bold;
    }

    .el-button--text:focus {
        color: #000000;
        font-weight: bold;
    }

</style>
